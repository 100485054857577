import { GET_CURRENT_USER, GetCurrentUserQuery } from '@api/client'
import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useCallback,
} from 'react'
import { useQuery } from '@apollo/client'
import {
  useAuth,
  useOrganization,
  useOrganizationList,
  useUser,
} from '@clerk/clerk-react'
import { useRouter } from '@app/nextjs'
import { usePostHog } from 'posthog-js/react'

type GetCurrentUserResult = NonNullable<GetCurrentUserQuery>
type Projects = GetCurrentUserResult['projects']
export type Project = Projects[0]
export type Environment = Project['environments'][0]
export type Datasource = Environment['datasources'][0]
export type Organization = Awaited<
  ReturnType<typeof useOrganization>['organization']
>
export type User = Awaited<ReturnType<typeof useUser>['user']>

interface CurrentUserContextType {
  user?: User
  isLoading: boolean
  projects?: Projects
  projectId?: string
  project?: Project
  tenant?: Organization
  tenantId?: string
  setProjectId: (projectId: string) => void
  invalidate: () => void
}

export const CurrentUserContext = createContext<
  CurrentUserContextType | undefined
>(undefined)

export interface CurrentUserProviderProps {
  children: React.ReactNode
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  children,
}) => {
  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(GET_CURRENT_USER)

  const router = useRouter()

  const posthog = usePostHog()

  const [projectId, setProjectIdInternal] = useState<string | undefined>(
    undefined,
  )

  const setProjectId = useCallback((id: string | undefined) => {
    setProjectIdInternal(id)
  }, [])

  const { user, isSignedIn, isLoaded: isUserLoaded } = useUser()
  const { organization, isLoaded: isOrgLoaded } = useOrganization()

  const projects = useMemo(() => data?.projects, [data])

  const project = useMemo(
    () => (projectId ? projects?.find((p) => p.id === projectId) : undefined),
    [projectId, projects],
  )
  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: true,
  })

  useEffect(() => {
    if (
      (projects?.length || 0) >= 1 &&
      (projectId === undefined ||
        projects?.find((p) => p.id === projectId) === undefined)
    ) {
      const firstProject = projects?.[0]
      const firstProjectId = firstProject?.id
      setProjectId(firstProjectId)
    }
  }, [projectId, projects, project, setProjectId])

  const currentUser = useMemo(() => {
    return data?.currentUser[0]
  }, [data])

  const lastTenant = useMemo(() => {
    return currentUser?.userTenants.find(
      (ut) => ut.tenant.clerkId === organization?.id,
    )?.tenant
  }, [currentUser, organization])

  const [sentPosthogIdentify, setSentPosthogIdentify] = React.useState(false)

  useEffect(() => {
    if (user && posthog && !sentPosthogIdentify) {
      posthog?.identify(user.id, {
        username: user.username,
        email: user.primaryEmailAddress?.emailAddress,
        ...user.publicMetadata,
      })
      setSentPosthogIdentify(true)
    }
  }, [posthog, sentPosthogIdentify, user])

  useEffect(() => {
    if (
      !isLoading &&
      isOrgLoaded &&
      isUserLoaded &&
      !router.route.startsWith('/getting-started') &&
      setActive &&
      !projectId
    ) {
      const org = userMemberships.data ? userMemberships.data[0] : null
      if (org) {
        setActive(org)
      }

      if (!projects?.length) {
        console.log('Redirecting to getting started so project is set up')
        router.push('/getting-started/create-project')
      }
    }
  }, [
    currentUser,
    isLoading,
    isOrgLoaded,
    isUserLoaded,
    organization?.id,
    projectId,
    router,
    setActive,
    userMemberships.data,
    projects?.length,
  ])

  const contextValue = {
    user,
    isLoading: isLoading || !isOrgLoaded || !isUserLoaded,
    projects,
    projectId,
    project,
    setProjectId,
    tenant: organization,
    tenantId: lastTenant?.id,
    invalidate: () => refetch(),
  }

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => {
  const context = React.useContext(CurrentUserContext)
  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }
  return context
}
